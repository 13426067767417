<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <b-row class="align-end">
        <b-col class="company-details__rowData dflex valign-end">
          <div>Директору {{ companyData.ShortName }}</div>
          <div>{{ companyData.ChiefFullNameDative }}</div>
          <span>от</span>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="headerUserData.ResponsiblePersonPosition"
              class="company-details__input"
              placeholder="Должность"
            />
          </div>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="headerUserData.ResponsiblePersonFullName"
              class="company-details__input"
              placeholder="ФИО ответственного лица"
            />
          </div>
          <span>
            {{ customerData.Name }}
          </span>
          <div></div>
          <div></div>
        </b-col>
      </b-row>
      <br />
      <br />
      <br />

      <!-- Тело заявления -->
      <b-row>
        <b-col class="statement-type">
          <p>{{ statementTitle }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Прошу заменить реквизиты компании на следующие:</p>
        </b-col>
      </b-row>
      <b-row v-for="(item, index) in userFormData" :key="index" align-v="center">
        <b-col
          md="8"
          lg="12"
          class="dflex valign-center align-center sm-align-between md-align-start"
        >
          <span>{{ item.name }} </span>
          <div class="company-details__input_wrap">
            <BaseTextInput
              :id="item.id"
              v-model="item.value"
              class="company-details__input"
            />
          </div>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col class="dflex align-end valign-center">
          <BaseTextInput v-model="seoPosition" class="company-details__input"/>
          <span> ____________ / </span>
          <BaseTextInput v-model="seoFullName" class="company-details__input"/>
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { mapState } from 'vuex';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      statementTitle: 'Заявление',
      headerUserData: {
        ResponsiblePersonPosition: '',
        ResponsiblePersonFullName: ''
      },
      seoPosition: '',
      seoFullName: '',
      userFormData: {}
    };
  },

  components: {
    BaseTextInput,
    ServicesDescription
  },

  mounted() {
    this.userFormData = this.currentData.map((el) => ({ ...el }));
  },

  computed: {
    ...mapState({
      companyData: (state) => state.statements.module.companyData,
      customerData: (state) => state.statements.module.customerData
    }),

    currentData() {
      return [
        { name: 'Наименование: ', value: this.customerData.Name, id: 'org-name' },
        {
          name: 'Должность руководителя: ',
          value: '',
          id: 'ceo-pos'
        },
        { name: 'ФИО руководителя: ', value: '', id: 'ceo-name' },
        { name: 'Почтовый адрес: ', value: this.customerData.PostAddress, id: 'address-post' },
        {
          name: 'Юридический адрес: ',
          value: this.customerData.LegalAddress,
          id: 'address-legal'
        },
        {
          name: 'Физический адрес: ',
          value: this.customerData.PhysicalAddress,
          id: 'address-fact'
        },
        { name: 'Email: ', value: this.customerData.Email, id: 'email' },
        { name: 'Факс: ', value: this.customerData.Fax, id: 'fax' },
        { name: 'Телефон: ', value: this.customerData.Phone, id: 'phone' },
        { name: 'Город: ', value: this.customerData.City, id: 'city' },
        { name: 'Банк: ', value: this.customerData.Bank, id: 'bank' },
        { name: 'БИК: ', value: this.customerData.Bik, id: 'bik' },
        { name: 'ИНН/КПП: ', value: this.customerData.Inn, id: 'inn-kpp' },
        { name: 'к/сч: ', value: this.customerData.Kch, id: 'k-account' },
        { name: 'р/сч: ', value: this.customerData.Rch, id: 'r-account' }
      ];
    }
  },

  methods: {
    createModelToSend() {
      const [
        Name,
        ChiefPosition,
        ChiefFullName,
        PostAddress,
        LegalAddress,
        PhysicalAddress,
        Email,
        Fax,
        Phone,
        City,
        Bank,
        Bik,
        Inn,
        Kch,
        Rch
      ] = this.userFormData;

      return {
        UpdateCustomerInfo: {
          Bank: Bank.value,
          Bik: Bik.value,
          ChiefFullName: ChiefFullName.value,
          ChiefPosition: ChiefPosition.value,
          City: City.value,
          Email: Email.value,
          Fax: Fax.value,
          Inn: Inn.value,
          Kch: Kch.value,
          LegalAddress: LegalAddress.value,
          Name: Name.value,
          Phone: Phone.value,
          PhysicalAddress: PhysicalAddress.value,
          PostAddress: PostAddress.value,
          Rch: Rch.value
        },
        CurrentCustomer: {
          ChiefFullName: this.seoFullName,
          ChiefPosition: this.seoPosition
        },
        ResponsiblePersonFullName: this.headerUserData.ResponsiblePersonFullName,
        ResponsiblePersonPosition: this.headerUserData.ResponsiblePersonPosition
      };
    },

    getDocument() {
      this.createDocument(this.createModelToSend());
    }
  }
};
</script>

<style></style>
